<template>
  <div>
    <PackingProcess
      v-if="command && command.id && basket && basket.id"
      :key="packingKey"
      :orders="orders"
      :basket="basket"
      :command="command"
      @success="success"
      @finishBasket="finishBasket"
    />

    <input-qr-scan-model
      ref="inputQr"
      v-else
      v-model.number="code"
      :label="$t('labels.basket_code')"
      @keyupEnter="scanBasket"
    ></input-qr-scan-model>

    <v-dialog v-model="dialogConfirm" persistent max-width="350px">
      <ConfirmBox
        v-if="dialogConfirm"
        :title="$t('labels.packing_table_code')"
        :description="$t('labels.scan_packing_table_description')"
        :label="$t('labels.packing_table_code')"
        :placeholder="$t('labels.packing_table_code')"
        :codes="checkingCodes"
        :show-cancel-button="false"
        @confirm="setPackingTable"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { POS_TABLE_PACKING } from "@/libs/const";
import moment from "moment";

export default {
  name: "Packing",
  components: {
    PackingProcess: () => import("@/components/order_online/PackingProcess"),
    ConfirmBox: () => import("@/components/common/ConfirmBox"),
  },
  data: () => ({
    code: null,
    basket: {},
    command: {},
    orders: [],
    tables: [],
    checkingCodes: [],
    dialogConfirm: false,
  }),
  computed: {
    packingKey() {
      if (!this.orders || this.orders.length === 0) {
        return "packingKey";
      }
      const ordArr = [...this.orders].map((o) => o.id);
      return ordArr.join("");
    },
  },
  watch: {},
  mounted() {
    this.getPackingTable();
  },
  methods: {
    focusInput() {
      setTimeout(() => {
        this.$refs.inputQr.focusInput();
      }, 200);
    },
    getPackingTable() {
      const table = localStorage.getItem("_pktb");
      if (!table) {
        this.showConfirmDialog();
        return false;
      }
      const { date, code, id } = JSON.parse(table);
      const today = moment().format("YYYYMMDD");
      if (!date || !code || !id || date !== today) {
        this.showConfirmDialog();
        return false;
      }
      this.focusInput();
    },

    showConfirmDialog() {
      httpClient
        .post("/get-table", { type: POS_TABLE_PACKING })
        .then(({ data }) => {
          const checkingCodes = [...data].map((d) => d.code);
          this.checkingCodes = [...checkingCodes];
          this.tables = [...data];
          this.dialogConfirm = true;
        });
    },

    hideConfirmDialog() {
      this.dialogConfirm = false;
    },

    async setPackingTable(code) {
      const { employee } = window.me;
      if (!code) {
        this.$vToastify.error(this.$t("messages.packing_table_code_required"));
        this.$root.$emit("playErrorAudio");
        return false;
      }
      const table = this.tables.find((t) => t.code === code);
      if (!table) {
        this.$vToastify.error(this.$t("messages.packing_table_code_wrong"));
        this.$root.$emit("playErrorAudio");
        return false;
      }
      if (table.id_employee && table.id_employee !== employee.id) {
        this.$vToastify.error(
          this.$t("messages.packing_table_code_using_other_employee", {
            name: table.employee_name,
          })
        );
        this.$root.$emit("playErrorAudio");
        return false;
      }

      try {
        await httpClient.post("/using-table", {
          id: table.id,
          type: POS_TABLE_PACKING,
        });
        const today = moment().format("YYYYMMDD");
        localStorage.setItem(
          "_pktb",
          JSON.stringify({ date: today, code: table.code, id: table.id })
        );
        this.$root.$emit("playSuccessAudio");
        this.focusInput();
        this.hideConfirmDialog();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.$root.$emit("playErrorAudio");
      }
    },
    async scanBasket() {
      if (!this.code) {
        return false;
      }
      if (this.isLoading) {
        this.code = null;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/order-online-packing-get-basket",
          {
            code: this.code,
          }
        );
        const { basket, command, orders } = data;

        const uOrders = [...orders].map((o) => {
          const oItems1 = o.items.split(";");
          const oItems2 = [...oItems1].map((oi) => {
            const [
              id_goods,
              sku,
              sub_barcode,
              quantity,
              uid_printed_on_item,
              no_label,
              uid_scan_require,
            ] = oi.split("Ω");
            return {
              id_goods,
              sku,
              sub_barcode,
              quantity,
              uid_printed_on_item,
              no_label,
              uid_scan_require,
            };
          });
          const oItems3 = [];
          oItems2.forEach((io) => {
            const checkIO3 = oItems3.findIndex(
              (io3) => io3.id_goods == io.id_goods
            );
            io.quantity = +io.quantity;
            if (checkIO3 < 0) {
              oItems3.push(io);
            } else {
              oItems3[checkIO3].quantity += io.quantity;
            }
          });
          o.items = [...oItems3];
          return o;
        });

        this.basket = { ...basket };
        this.command = { ...command };
        this.orders = [...uOrders];
        console.log("this.basket", this.basket);
        console.log("this.command", this.command);
        console.log("this.orders", this.orders);
        this.isLoading = false;
        this.code = null;
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        this.isLoading = false;
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.$root.$emit("playErrorAudio");
      }
    },
    async success() {
      if (this.orders.length > 1) {
        this.code = this.basket.code;
        await this.scanBasket();
      } else {
        this.finishBasket();
      }
    },
    finishBasket() {
      this.code = null;
      this.basket = {};
      this.command = {};
      this.orders = [];
    },
  },
};
</script>

<style scoped></style>
